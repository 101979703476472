<template>
  <v-data-table
    :value="selected"
    :headers="headers"
    :items="items"
    :items-per-page="10"
    :search="search"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    :hide-default-footer="!!$slots.footer"
    show-select
    item-key="_id"
    sort-by="name"
    multi-sort
    @input="$emit('update:selected', $event)"
  >
    <template v-slot:[`item.control`]="{ item }">
      <v-simple-checkbox
        v-model="item.control"
        disabled
      />
    </template>
    <template v-slot:[`item.alterationAllowed`]="{ item }">
      <v-simple-checkbox
        v-model="item.alterationAllowed"
        disabled
      />
    </template>
    <template v-slot:[`item.alteration`]="{ item }">
      <v-simple-checkbox
        v-model="item.alteration"
        disabled
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        :to="`${item._id}`"
        append
        icon
      >
        <v-icon>edit</v-icon>
      </v-btn>
    </template>

    <template v-if="$slots.footer" v-slot:footer="{ props, on }">
      <v-row
        align="center"
        no-gutters
        class="px-2"
        style="border-top: thin solid rgba(0, 0, 0, 0.12)"
      >
        <v-col cols="auto">
          <slot name="footer" />
        </v-col>
        <v-col>
          <v-data-footer v-bind="props" style="border: none" v-on="on" />
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'OperationCategoryList',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    selected: {
      type: Array,
      default: () => ([]),
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers () {
      const baseHeaders = [
        {
          text: 'Nom',
          align: 'center',
          value: 'name',
        },
        {
          text: 'Nbr d\'opération',
          align: 'center',
          value: 'operationTypeList.length',
        },
      ]

      if (this.light) {
        return baseHeaders
      }

      return [
        ...baseHeaders,
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          align: 'center',
          width: '15%',
        },
      ]
    },
    items () {
      return this.value
    },
  },
}
</script>
