<template>
  <ag-card-container
    :search.sync="search"
    title="Liste des catégories d'opérations"
    fluid
    no-content-padding
  >
    <template #left>
      <v-btn
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
    </template>

    <operation-category-list
      :value="operationCategoryList"
      :selected.sync="selectedOperationCategoryList"
      :loading="loadingData"
      :search="search"
    />

    <ag-float-button-container>
      <ag-confirm-dialog
        v-if="selectedOperationCategoryList.length"
        title="Confirmation"
        :message="removeMessage"
        cancel-label="Annuler"
        confirm-label="Supprimer"
        validation-btn-color="error"
        @confirm="removeSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRemove"
            color="error"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>delete</v-icon>
            <v-chip
              x-small
              class="px-1 float-chip font-weight-black"
              color="white"
              text-color="error"
              v-text="selectedOperationCategoryList.length"
            />
          </v-btn>
        </template>
      </ag-confirm-dialog>
      <v-btn
        to="create"
        append
        color="accent"
        fab
      >
        <v-icon>add</v-icon>
      </v-btn>
    </ag-float-button-container>

    <!-- RouterView for modals -->
    <router-view />
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import OperationCategoryList from '../components/OperationCategoryList'

export default {
  name: 'OperationCategoryListContainer',
  components: {
    AgCardContainer,
    AgFloatButtonContainer,
    AgConfirmDialog,
    OperationCategoryList,
  },
  data: () => ({
    operationCategoryList: [],
    selectedOperationCategoryList: [],
    search: '',
    loadingData: false,
    loadingRemove: false,
    loadingExport: false,
  }),
  computed: {
    removeMessage () {
      const count = this.selectedOperationCategoryList.length
      return `Souhaitez-vous supprimer ${count} catégorie(s) d'opération(s)`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'OperationCategoryList' || !this.operationCategoryList.length) {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        this.operationCategoryList = await Repositories.operationCategory.getList()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async removeSelected () {
      try {
        this.loadingRemove = true
        const count = this.selectedOperationCategoryList.length
        await Repositories.operationCategory.delete(
          this.selectedOperationCategoryList.map(select => select._id),
        )
        this.showSuccessNotification(`Vous avez supprimé ${count} catégorie(s) d'opération(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRemove = false
        this.selectedOperationCategoryList = []
        this.fetchData()
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const exportConfig = {
          columns: [
            { header: 'Nom', key: 'name' },
            { header: 'Nombre d\'opération', key: 'operationTypeLenght' },
            { header: 'Id', key: '_id' },
          ],
          view: [{ state: 'frozen', xSplit: 1, ySplit: 1 }],
          sheetName: 'Liste des catégories d\'opération',
        }
        const data = this.operationCategoryList.map(item => ({
          _id: item._id,
          name: item.name,
          operationTypeLenght: item.operationTypeList.length,
        }))
        await createExcelFile([{ dataToExport: data, config: exportConfig }], 'Liste des catégories d\'opération')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
